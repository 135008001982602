import React, { FunctionComponent, useCallback } from 'react'
import styled from 'styled-components'
import Colors from '../../../tokens/Colors'
import { rem } from '../../../utils/rem'

export enum ShareOptionType {
  COPY = 'Copy link',
  FACEBOOK = 'Facebook',
  LINKED_IN = 'LinkedIn',
  PINTEREST = 'Pinterest',
  WEIBO = 'Weibo',
}

export interface ShareOption {
  hide?: boolean
  icon: string
  type: ShareOptionType
}

interface ShareModalProps {
  onClick?: (type: ShareOptionType) => void
  options: ShareOption[]
}

const ShareModal: FunctionComponent<ShareModalProps> = ({
  options,
  onClick,
}) => {
  /**
   * Option click handler
   */
  const onOptionClick = useCallback(
    (type) => {
      onClick && onClick(type)
    },
    [onClick],
  )

  return (
    <StyledShareModal>
      {options.map(({ type, hide, icon }, index) =>
        !hide ? (
          <StyledButton
            key={`option-${index}`}
            onClick={(): void => {
              onOptionClick(type)
            }}
          >
            <StyledIcon alt={type} src={icon} />
            {type}
          </StyledButton>
        ) : null,
      )}
    </StyledShareModal>
  )
}

export default ShareModal

const StyledIcon = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${rem(32)};
  width: ${rem(24)};
`

const StyledButton = styled.button`
  width: 100%;
  padding: ${rem(12)} ${rem(32)} ${rem(12)} ${rem(72)};
  border: none;
  background: none;
  -webkit-appearance: none;
  text-align: left;
  cursor: pointer;
  font-family: inherit;
  font-weight: 700;
  line-height: ${rem(24)};
  color: ${Colors.OSLO_GREY};
  font-size: ${rem(15)};
  white-space: nowrap;
  position: relative;
  outline: none;

  &:hover {
    text-decoration: underline;
  }
`

const StyledShareModal = styled.div`
  padding: ${rem(12)} 0 ${rem(16)};
  margin: 0 0 ${rem(12)};
  width: ${rem(176)};
  background: ${Colors.ALABASTER};
  position: relative;

  &:after {
    content: '';
    width: ${rem(18)};
    height: ${rem(18)};
    background: ${Colors.ALABASTER};
    position: absolute;
    top: 100%;
    left: 50%;
    transform: rotate(-45deg) translate(-9px, -9px);
    transform-origin: top left;
  }
`
