import React, { FunctionComponent, ReactNode, Ref } from 'react'
import styled, { css, SimpleInterpolation } from 'styled-components'
import Colors from '../../../../tokens/Colors'
import { ImageNodeData } from '../../../../contexts/images/ImagesContext'
import Img from 'gatsby-image'
import { motion } from 'framer-motion'
import { rem } from '../../../../utils/rem'

export interface Size {
  height: number
  width: number
}

interface BackgroundProps {
  backgroundRef: Ref<HTMLDivElement>
  canvasRef: Ref<HTMLCanvasElement>
  children: ReactNode
  isFixed: boolean
  isLoaded: boolean
  overlayOpacity: number
  poster?: ImageNodeData | null
  size: Size | null
}

const Background: FunctionComponent<BackgroundProps> = ({
  backgroundRef,
  canvasRef,
  children,
  isFixed,
  isLoaded,
  overlayOpacity,
  poster,
  size,
}) => (
  <StyledBackground
    animate={{
      scale: 1,
      transition: {
        delay: 1,
        duration: 0.6,
      },
    }}
    initial={{ scale: 1.06 }}
    isFixed={isFixed}
    ref={backgroundRef}
  >
    <Canvas isVisible={isLoaded} ref={canvasRef} {...size} />
    {poster && (
      <Poster animate={{ opacity: isLoaded ? 0 : 1 }} size={size}>
        <Img fluid={poster.fluid} style={{ width: '100%', height: '100%' }} />
      </Poster>
    )}
    <Overlay opacity={overlayOpacity} />
    {children}
  </StyledBackground>
)

interface StyledBackgroundProps {
  isFixed: boolean
}

const StyledBackground = styled(motion.div)<StyledBackgroundProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${({ isFixed }): SimpleInterpolation =>
    isFixed &&
    css`
      position: fixed;
      top: 0;
      bottom: auto;
    `}
`

interface CanvasProps {
  isVisible: boolean
}

const Canvas = styled.canvas<CanvasProps>`
  position: absolute;
  visibility: ${({ isVisible }): string => (isVisible ? 'visible' : 'hidden')};
  width: 100vw;
  height: 100vh;
`

interface PosterProps {
  size: Size | null
}

const Poster = styled(motion.div)<PosterProps>`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: ${({ size }): string => (size ? rem(size.width) : 'auto')};
  height: ${({ size }): string => (size ? rem(size.height) : 'auto')};
`

interface OverlayProps {
  opacity: number
}

const Overlay = styled.div.attrs<OverlayProps>(({ opacity }) => ({
  style: {
    opacity,
  },
}))<OverlayProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${Colors.BLACK_20};
  transition: opacity 100ms linear;
`

export default Background
