import { Variants } from 'framer-motion'

export const wrapperAnimation = (
  distanceToCenter: number,
  exitActive: boolean,
): Variants => ({
  initial: {
    overflow: 'hidden',
    y: 0,
  },
  exit: exitActive
    ? {
        overflow: 'initial',
        y: distanceToCenter,
        transition: {
          duration: 0.5,
          ease: 'easeOut',
        },
      }
    : {},
})

export const backgroundAnimation = (
  scale: number,
  exitActive: boolean,
): Variants => ({
  initial: {
    scale: 1,
  },
  hoverScaleIn: {
    scale: 1.06,
    transition: {
      ease: 'easeOut',
    },
  },
  hoverScaleOut: {
    scale: 1,
    transition: {
      ease: 'easeIn',
    },
  },
  exit: exitActive
    ? {
        scale,
        transition: {
          duration: 0.5,
          ease: 'easeOut',
        },
      }
    : {},
})

export const overlayAnimation = {
  initial: {
    opacity: 0.2,
  },
  opacityIn: {
    opacity: 0.4,
    transition: {
      ease: 'easeOut',
    },
  },
}
