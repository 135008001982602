import { motion } from 'framer-motion'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Colors from '../../../tokens/Colors'
import { rem } from '../../../utils/rem'

export interface ProgressIndicatorProps {
  className?: string
  progress: number
}

const ProgressIndicator: FunctionComponent<ProgressIndicatorProps> = ({
  className,
  progress,
}) => (
  <StyledProgressIndicator
    animate={{ scaleX: progress / 100 }}
    className={className}
    exit={{ scaleX: 1 }}
    initial={{ scaleX: 0 }}
    transition={{
      ease: 'easeOut',
      duration: 1,
    }}
  />
)

const StyledProgressIndicator = styled(motion.div)`
  width: 100%;
  height: ${rem(3)};
  background-color: ${Colors.WHITE};
  transform-origin: 0 0;
`

export default ProgressIndicator
