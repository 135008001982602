/**
 */

interface CoverDimensions {
  height: number
  width: number
}

const cover = (
  parentWidth: number,
  parentHeight: number,
  childWidth: number,
  childHeight: number,
): CoverDimensions => {
  const childRatio = childWidth / childHeight
  const parentRatio = parentWidth / parentHeight
  let width = parentWidth
  let height = parentHeight

  if (childRatio < parentRatio) {
    height = width / childRatio
  } else {
    width = height * childRatio
  }

  return {
    width,
    height,
  }
}

const drawCoverImage = (
  context: CanvasRenderingContext2D,
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
): void => {
  const { width, height } = cover(
    canvas.width,
    canvas.height,
    image.width,
    image.height,
  )

  const xOffset = (canvas.width - width) / 2
  const yOffset = (canvas.height - height) / 2

  context.drawImage(image, xOffset, yOffset, width, height)
}

export default drawCoverImage
