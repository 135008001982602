import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import SocialShare from '../molecules/social-share/SocialShare'
import Sections, { SectionProps } from '../organisms/sections/Sections'
import useResizeObserver from 'use-resize-observer/polyfilled'
import { rem } from '../../utils/rem'
import Colors from '../../tokens/Colors'
import Footer from '../organisms/footer/Footer'
import { Helmet } from 'react-helmet'
import Hero from '../organisms/hero/Hero'
import MetaData from '../../types/MetaData'
import { motion } from 'framer-motion'
import NextArtPiece from '../molecules/next-art-piece/NextArtPiece'
import styled from 'styled-components'
import { useAppState } from '../../contexts/app-state/AppStateContext'
import useImageData from '../../hooks/use-image-data/useImageData'

interface ProjectInfo {
  artist: string
  meta: MetaData
  slug: string
  title: string
}

export interface ProjectProps extends ProjectInfo {
  next: ProjectInfo
  numberOfFrames: number
  sections: SectionProps[]
}

interface ProjectPageProps extends ProjectInfo {
  location: Location
  pageContext: ProjectProps
}

const ProjectPage: FunctionComponent<ProjectPageProps> = ({
  location,
  pageContext: { artist, meta, numberOfFrames, next, sections, slug, title },
}) => {
  const [{ projects }] = useAppState()
  const ogImage = useImageData(`social/${slug}.jpg`)
  const [nextProject, setNextProject] = useState<ProjectInfo | undefined>(next)
  const footerWrapperRef = useRef<HTMLDivElement | null>(null)

  const { height: footerWrapperHeight } = useResizeObserver({
    ref: footerWrapperRef,
  })

  const [
    isNextArtPieceTransitionActive,
    setIsNextArtPieceTransitionActive,
  ] = useState(false)

  /**
   * Search current project inside app state, set the updated next in state
   */
  useEffect(() => {
    setNextProject(
      projects?.find(({ slug: projectSlug }) => projectSlug === slug)?.next,
    )
  }, [slug, projects])

  /**
   * Reset the isNextArtPieceTransitionActive boolean when the user use the browser navigation
   */
  useEffect(() => {
    const onPopstate = (): void => {
      setIsNextArtPieceTransitionActive(false)
    }

    window.addEventListener('popstate', onPopstate)

    return (): void => {
      window.removeEventListener('popstate', onPopstate)
    }
  }, [])

  const onNextArtPieceClick = useCallback(() => {
    setIsNextArtPieceTransitionActive(true)
  }, [])

  return (
    <Fragment>
      <FooterWrapper
        isNextArtPieceTransitioning={isNextArtPieceTransitionActive}
        ref={footerWrapperRef}
      >
        {nextProject && (
          <NextArtPiece
            artist={nextProject.artist}
            onClick={onNextArtPieceClick}
            slug={nextProject.slug}
            title={nextProject.title}
          />
        )}
        <Footer />
      </FooterWrapper>
      <StyledProjectPage bottomSpace={footerWrapperHeight}>
        <Helmet>
          <title>{meta.title}</title>
          <meta content={meta.description} name="description" />
          <meta content={meta.og?.title || meta.title} name="og:title" />
          <meta
            content={meta.og?.description || meta.description}
            name="og:description"
          />
          {ogImage?.fixed?.src && (
            <meta content={meta.siteUrl + ogImage.fixed.src} name="og:image" />
          )}
        </Helmet>
        <Hero
          artist={artist}
          numberOfFrames={numberOfFrames}
          slug={slug}
          title={title}
        />
        <Sections sections={sections} />
        <SocialShare
          media={`${meta.siteUrl}${ogImage?.fixed?.src || ''}`}
          title={meta.title}
        />
      </StyledProjectPage>
    </Fragment>
  )
}

interface FooterWrapperProps {
  isNextArtPieceTransitioning: boolean
}

const FooterWrapper = styled(motion.div)<FooterWrapperProps>`
  background: ${Colors.WHITE};
  height: min-content;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: ${({ isNextArtPieceTransitioning }): number =>
    isNextArtPieceTransitioning ? 2 : 1};
`

interface StyledProjectPageProps {
  bottomSpace?: number
}

const StyledProjectPage = styled.div<StyledProjectPageProps>`
  overflow: hidden;
  margin-bottom: ${({ bottomSpace }): string => rem(bottomSpace || 0)};
  background: ${Colors.WHITE};
  position: relative;
  z-index: 1;
`

export default ProjectPage
