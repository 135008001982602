import React, { forwardRef, FunctionComponent, Ref } from 'react'
import { subtitleAnimation, titleAnimation } from './animations'
import Colors from '../../../../tokens/Colors'
import { mediaQueries } from '../../../../utils/mediaQueries'
import { motion } from 'framer-motion'
import { rem } from '../../../../utils/rem'
import { spacings } from '../../../../tokens/Spacings'
import styled from 'styled-components'
import Title, { TitleSizes } from '../../../atoms/title/Title'

export interface ContentProps {
  artist?: string
  ref?: Ref<HTMLDivElement | null>
  title?: string
  top: number
}

const Content: FunctionComponent<ContentProps> = forwardRef(
  ({ artist, title, top }, ref) => (
    <StyledContent ref={ref} top={top}>
      <motion.div
        animate={['initial', 'visible']}
        initial="initial"
        variants={titleAnimation}
      >
        <Title
          responsiveSizes={{
            tablet: TitleSizes.xxLarge,
          }}
          size={TitleSizes.xLarge}
        >
          {title}
        </Title>
      </motion.div>
      <motion.div
        animate={['initial', 'visible']}
        initial="initial"
        variants={subtitleAnimation}
      >
        <Title
          level="h2"
          responsiveSizes={{
            tablet: TitleSizes.medium,
          }}
          size={TitleSizes.small}
        >
          <span dangerouslySetInnerHTML={{ __html: artist || '' }} />
        </Title>
      </motion.div>
    </StyledContent>
  ),
)

Content.displayName = 'Content'

interface StyledContentProps {
  top: number
}

const StyledContent = styled.div<StyledContentProps>`
  position: absolute;
  top: ${({ top }): string => `${top}px`};
  left: 0;
  width: 100%;
  transform: translate3d(0, -50%, 0);
  padding: 0 ${rem(spacings.side.root)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: ${Colors.WHITE};

  ${mediaQueries.tablet} {
    padding: 0 ${rem(spacings.side.tablet)};
  }
`

export default Content
