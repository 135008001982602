import { AnimatePresence, usePresence } from 'framer-motion'
import Content, { ContentProps } from './content/Content'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Background from './background/Background'
import clamp from 'lodash/clamp'
import drawCoverImage from '../../../utils/drawCoverImage'
import ProgressIndicator from '../../atoms/progress-indicator/ProgressIndicator'
import { rem } from '../../../utils/rem'
import ScrollIndicator from '../../atoms/scroll-indicator/ScrollIndicator'
import { spacing } from '../../../utils/spacing'
import styled from 'styled-components'
import { useAppState } from '../../../contexts/app-state/AppStateContext'
import useBreakPoints from '../../../hooks/use-breakpoints/useBreakPoints'
import useDictionary from '../../../hooks/use-dictionary/useDictionary'
import useImageData from '../../../hooks/use-image-data/useImageData'
import useImageLoader from '../../../hooks/use-image-loader/useImageLoader'
import useResizeObserver from 'use-resize-observer/polyfilled'
import useWindowSize from '../../../hooks/use-window-size/useWindowSize'

interface Hero extends Omit<ContentProps, 'top'> {
  image?: string
  numberOfFrames: number
  scrollIndicatorText?: string
  // The scrollThreshold determines the height of the hero/the amount of pixels per photo
  // By default it's 100 which means an every 100 pixels a new frame will be shown
  scrollThreshold?: number
  slug: string
}

const Hero: FunctionComponent<Hero> = ({
  artist,
  image,
  numberOfFrames,
  scrollIndicatorText = 'scrollToExplore',
  scrollThreshold = 60,
  slug,
  title,
}) => {
  const backgroundRef = useRef<HTMLDivElement | null>(null)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const heroRef = useRef<HTMLDivElement | null>(null)
  const requestId = useRef<number | null>(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [overlayOpacity, setOverlayOpacity] = useState(1)
  const [isFixed, setIsFixed] = useState(false)
  const [progress, setProgress] = useState(0)
  const [isPresent, safeToRemove] = usePresence()
  const imageData = useImageData(`hero/${image || ''}`)
  const { isTablet } = useBreakPoints()
  const dictionary = useDictionary()
  const posterLandscape = useImageData(`hero/${slug}/landscape/poster.jpg`)
  const posterPortrait = useImageData(`hero/${slug}/portrait/poster.jpg`)
  const [state] = useAppState()
  const [windowWidth, windowHeight] = useWindowSize()
  const poster = isTablet ? posterLandscape : posterPortrait

  const { width: canvasWidth, height: canvasHeight } = useResizeObserver({
    ref: canvasRef,
  })

  const images = useMemo(() => {
    if (
      typeof state.isWebPSupported === 'boolean' &&
      typeof windowWidth !== 'undefined'
    ) {
      return imageData
        ? [imageData.fluid.src]
        : Array.from(Array(numberOfFrames).keys()).map(
            (number) =>
              `/images/hero/${slug}/${
                isTablet ? 'landscape' : 'portrait'
              }/thumb${(number + 1).toString().padStart(4, '0')}.${
                state.isWebPSupported ? 'webp' : 'jpg'
              }`,
          )
    }

    return []
  }, [
    imageData,
    isTablet,
    numberOfFrames,
    slug,
    state.isWebPSupported,
    windowWidth,
  ])

  const [loadedImages, isLoaded, loadingProgress] = useImageLoader(images)

  useEffect(() => {
    if (!isPresent) {
      safeToRemove && safeToRemove()
    }
  }, [isPresent])

  // disable scrolling while loading
  useEffect((): (() => void) => {
    document.body.style.overflow = isLoaded ? '' : 'hidden'

    return (): void => {
      document.body.style.overflow = ''
    }
  }, [isLoaded])

  const onScroll = useCallback(() => {
    if (
      canvasRef.current &&
      contentRef.current &&
      // This will be 0 when the component is not displayed
      contentRef.current.offsetHeight > 0 &&
      heroRef.current
    ) {
      // Calculate the overlay opacity
      const overlayOpacity =
        1 -
        (1 /
          (window.scrollY +
            contentRef.current.getBoundingClientRect().top +
            contentRef.current.offsetHeight)) *
          window.scrollY

      setOverlayOpacity(Math.max(0, overlayOpacity))

      // The image sequence needs to start scrubbing when the subtitle leaves the screen
      const scrollCorrection =
        contentRef.current.getBoundingClientRect().top +
        contentRef.current.offsetHeight

      const progress =
        (100 /
          (heroRef.current.offsetHeight -
            window.innerHeight -
            (window.scrollY + scrollCorrection))) *
        -scrollCorrection

      setProgress(Math.round(clamp(progress, 0, 100)))

      const currentIndex = Math.ceil(((numberOfFrames - 1) / 100) * progress)

      if (currentIndex <= 0) {
        setCurrentIndex(0)
      } else if (currentIndex < numberOfFrames) {
        setCurrentIndex(currentIndex)
      } else {
        setCurrentIndex(numberOfFrames - 1)
      }

      if (
        (currentIndex >= numberOfFrames &&
          window.scrollY >
            heroRef.current.offsetHeight - canvasRef.current.offsetHeight) ||
        numberOfFrames === 1
      ) {
        setIsFixed(false)
      } else if (isPresent) {
        setIsFixed(true)
      }
    }
  }, [
    canvasRef.current,
    contentRef.current,
    heroRef.current,
    isPresent,
    numberOfFrames,
  ])

  const renderImage = useCallback(() => {
    if (canvasRef.current && isLoaded && windowWidth && windowHeight) {
      const context = canvasRef.current.getContext('2d')
      const image = loadedImages[images[currentIndex]]

      if (context && image) {
        context.imageSmoothingEnabled = false
        drawCoverImage(context, image, canvasRef.current)
      }
    }
  }, [
    windowWidth,
    windowHeight,
    canvasRef.current,
    currentIndex,
    images,
    isLoaded,
  ])

  /**
   * This useEffect checks for changes in the contentRef.current.offsetHeight
   * When you go from a project to another project the previous canvas will be hidden
   * If you then use the browser navigation to go back the canvas needs to be rendered
   */
  useEffect(() => {
    if ((contentRef.current?.offsetHeight || 0) > 0) {
      onScroll()
      renderImage()
    }
  }, [contentRef.current?.offsetHeight])

  /**
   * Rerender canvas when window size changes
   */
  useEffect(() => {
    renderImage()
  }, [windowWidth, windowHeight])

  useEffect(() => {
    onScroll()
    window.addEventListener('scroll', onScroll)

    return (): void => window.removeEventListener('scroll', onScroll)
  }, [onScroll])

  useEffect(() => {
    requestId.current = requestAnimationFrame(renderImage)

    return (): void => {
      if (requestId.current !== null) {
        cancelAnimationFrame(requestId.current)
      }
    }
  }, [renderImage])

  return (
    <StyledHero
      numberOfFrames={numberOfFrames}
      ref={heroRef}
      scrollThreshold={scrollThreshold}
    >
      <Background
        backgroundRef={backgroundRef}
        canvasRef={canvasRef}
        isFixed={isFixed}
        isLoaded={isLoaded}
        overlayOpacity={overlayOpacity}
        poster={poster}
        size={{
          width: canvasWidth || 0,
          height: canvasHeight || 0,
        }}
      >
        {isLoaded && (
          <ScrollIndicatorWrapper
            style={{ height: isFixed ? `${window.innerHeight}px` : '100%' }}
          >
            <ScrollIndicator
              progress={progress}
              text={dictionary.get(scrollIndicatorText)}
            />
          </ScrollIndicatorWrapper>
        )}
      </Background>
      <AnimatePresence>
        {loadingProgress !== 100 && (
          <StyledProgressIndicator progress={loadingProgress} />
        )}
      </AnimatePresence>
      <Content
        artist={artist}
        ref={contentRef}
        title={title}
        top={windowHeight / 2}
      />
    </StyledHero>
  )
}

interface StyledHeroProps {
  numberOfFrames: number
  scrollThreshold: number
}

const StyledProgressIndicator = styled(ProgressIndicator)`
  position: absolute;
  top: 0;
  left: 0;
`

const StyledHero = styled.div<StyledHeroProps>`
  ${spacing()};
  position: relative;
  min-height: 100vh;
  height: ${({ numberOfFrames, scrollThreshold }): string =>
    rem(numberOfFrames * scrollThreshold)};
  overflow: hidden;
`

const ScrollIndicatorWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`

export default Hero
