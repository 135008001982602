import { useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'

interface LoadedImages {
  [key: string]: HTMLImageElement
}

const useImageLoader = (images: string[]): [LoadedImages, boolean, number] => {
  let imagesLoaded = 0

  const [imageSources, setImageSources] = useState(images)
  const [allImagesLoaded, setAllImagesLoaded] = useState(false)
  const [progress, setProgress] = useState(0)
  const [loadedImages, setLoadedImages] = useState<LoadedImages>({})

  useEffect(() => {
    if (isEqual(imageSources, images)) {
      return
    }

    setImageSources(images)
    setAllImagesLoaded(false)
    setLoadedImages({})

    const loadedImages: LoadedImages = {}
    images.forEach((source: string) => {
      const image = new Image()

      // load event handler
      image.onload = (): void => {
        imagesLoaded++
        setProgress(Math.round(100 / (images.length / imagesLoaded)))

        if (imagesLoaded === images.length) {
          setAllImagesLoaded(true)
        }
      }
      // set source which will trigger onLoad event
      image.src = source
      loadedImages[source] = image
    })

    setLoadedImages(loadedImages)
  }, [images])

  return [loadedImages, allImagesLoaded, progress]
}

export default useImageLoader
