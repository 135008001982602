import Img, { GatsbyImageFluidProps } from 'gatsby-image'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import useResizeObserver from 'use-resize-observer/polyfilled'
import useBreakPoints from '../../../hooks/use-breakpoints/useBreakPoints'
import Title, { TitleSizes } from '../../atoms/title/Title'
import Colors from '../../../tokens/Colors'
import { ImageNodeData } from '../../../contexts/images/ImagesContext'
import { Link } from 'gatsby'
import { mediaQueries } from '../../../utils/mediaQueries'
import { motion, usePresence } from 'framer-motion'
import { rem } from '../../../utils/rem'
import ScrollIndicator from '../../atoms/scroll-indicator/ScrollIndicator'
import { spacings } from '../../../tokens/Spacings'
import styled from 'styled-components'
import useDictionary from '../../../hooks/use-dictionary/useDictionary'
import useImageData from '../../../hooks/use-image-data/useImageData'
import {
  backgroundAnimation,
  overlayAnimation,
  wrapperAnimation,
} from './animations'

interface NextArtPieceProps {
  artist: string
  className?: string
  onClick?: () => void
  slug: string
  title: string
}

const NextArtPiece: FunctionComponent<NextArtPieceProps> = ({
  artist,
  className,
  onClick,
  slug,
  title,
}) => {
  const { isDesktop } = useBreakPoints()
  const [distanceToCenter, setDistanceToCenter] = useState(0)
  const [scaleFactor, setScaleFactor] = useState(0)
  const nextArtPieceWrapperRef = useRef<HTMLDivElement | null>(null)
  const [isPresent, safeToRemove] = usePresence()
  const [exitActive, setExitActive] = useState(false)

  useEffect(() => {
    if (!isPresent) {
      safeToRemove && safeToRemove()
    }
  }, [isPresent])

  const { height: nextArtPieceWrapperHeight } = useResizeObserver({
    ref: nextArtPieceWrapperRef,
  })

  const nextArtPieceImage = useImageData(
    `next-art-piece/${slug}.jpg`,
  ) as ImageNodeData

  const nextArtPieceImageNarrow = useImageData(
    `next-art-piece-narrow/${slug}.jpg`,
  ) as ImageNodeData

  const dictionary = useDictionary()
  const [hover, setHover] = useState(false)

  /**
   * Calculate the distance to center of screen
   */
  useEffect(() => {
    if (nextArtPieceWrapperRef.current) {
      const {
        top,
        height,
      } = nextArtPieceWrapperRef.current.getBoundingClientRect()
      const position = top + height / 2
      const centerY = window.innerHeight / 2

      setDistanceToCenter(centerY - position)
      setScaleFactor(window.innerHeight / height)
    }
  }, [nextArtPieceWrapperHeight, nextArtPieceWrapperRef.current])

  const onClickCallback = useCallback(() => {
    setExitActive(true)

    if (onClick) {
      onClick()
    }
  }, [onClick])

  const onHoverStart = useCallback((): void => {
    setHover(true)
  }, [])

  const onHoverEnd = useCallback((): void => {
    setHover(false)
  }, [])

  const backgroundImage = isDesktop
    ? nextArtPieceImage
    : nextArtPieceImageNarrow

  return (
    <StyledNexArtPieceWrapper
      exit="exit"
      ref={nextArtPieceWrapperRef}
      variants={wrapperAnimation(distanceToCenter, exitActive)}
    >
      <StyledNextArtPiece
        className={className}
        onClick={onClickCallback}
        to={`/projects/${slug}`}
      >
        <Inner onHoverEnd={onHoverEnd} onHoverStart={onHoverStart}>
          {backgroundImage && (
            <BackgroundImageWrapper
              animate={
                isPresent ? (hover ? 'hoverScaleIn' : 'hoverScaleOut') : ''
              }
              exit="exit"
              variants={backgroundAnimation(scaleFactor, exitActive)}
            >
              <BackgroundImage fluid={backgroundImage.fluid} />
              <Overlay
                animate={hover ? 'opacityIn' : 'initial'}
                variants={overlayAnimation}
              />
            </BackgroundImageWrapper>
          )}
          <Content>
            <Title
              responsiveSizes={{
                tablet: TitleSizes.xxLarge,
              }}
              size={TitleSizes.xLarge}
            >
              {title}
            </Title>
            <Title
              level="h2"
              responsiveSizes={{
                tablet: TitleSizes.medium,
              }}
              size={TitleSizes.small}
            >
              <span dangerouslySetInnerHTML={{ __html: artist }} />
            </Title>
          </Content>
          <ScrollIndicatorWrapper>
            <ScrollIndicator text={dictionary.get('next')} />
          </ScrollIndicatorWrapper>
        </Inner>
      </StyledNextArtPiece>
    </StyledNexArtPieceWrapper>
  )
}

const StyledNexArtPieceWrapper = styled(motion.div)`
  position: relative;
  height: ${rem(450)};
  overflow: hidden;
`

const StyledNextArtPiece = styled(Link)`
  text-decoration: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
`

const BackgroundImageWrapper = styled(motion.div)`
  position: relative;
  height: 100%;
`

const BackgroundImage = styled(Img)<GatsbyImageFluidProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate3d(-50%, -50%, 0);
`

const Inner = styled(motion.section)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Content = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 ${rem(spacings.side.root)};
  text-align: center;
  color: ${Colors.WHITE};

  ${mediaQueries.tablet} {
    padding: 0 ${rem(spacings.side.tablet)};
  }
`

const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${Colors.BLACK};
  opacity: 0.2;
`

const ScrollIndicatorWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
`

export default NextArtPiece
