import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import useDictionary from '../../../hooks/use-dictionary/useDictionary'
import Colors from '../../../tokens/Colors'
import { rem } from '../../../utils/rem'
import ShareModal, {
  ShareOption,
  ShareOptionType,
} from '../../atoms/share-modal/ShareModal'
import iconFacebook from '../../../images/icon-facebook.svg'
import iconLinkedIn from '../../../images/icon-linked-in.svg'
import iconPinterest from '../../../images/icon-pinterest.svg'
import iconWeibo from '../../../images/icon-weibo.svg'
import iconLink from '../../../images/icon-link.svg'
import iconShare from '../../../images/icon-share.svg'

interface SocialShareProps {
  media: string
  title: string
}

const SocialShare: FunctionComponent<SocialShareProps> = ({ media, title }) => {
  const dictionary = useDictionary()
  const [showModal, setShowModal] = useState(false)
  const shareModalWrapperRef = useRef<HTMLDivElement>(null)

  useEffect((): (() => void) => {
    const onDocumentClick = (event: Event): void => {
      const clickedInside = shareModalWrapperRef.current?.contains(
        event.target as Node,
      )

      if (!clickedInside && showModal) {
        setShowModal(false)
      }
    }

    document.addEventListener('click', onDocumentClick)

    return (): void => {
      document.removeEventListener('click', onDocumentClick)
    }
  })

  const shareOptions: ShareOption[] = [
    {
      type: ShareOptionType.FACEBOOK,
      icon: iconFacebook,
    },
    {
      type: ShareOptionType.LINKED_IN,
      icon: iconLinkedIn,
    },
    {
      type: ShareOptionType.PINTEREST,
      icon: iconPinterest,
    },
    {
      type: ShareOptionType.WEIBO,
      icon: iconWeibo,
    },
    {
      hide: !(
        typeof window !== `undefined` &&
        window.navigator &&
        window.navigator.clipboard
      ),
      type: ShareOptionType.COPY,
      icon: iconLink,
    },
  ]

  const onButtonClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()

      // show native share
      if (typeof window !== `undefined` && window.navigator.share) {
        window.navigator
          .share({
            url: window.location.href,
          })
          .catch((error) => console.warn(error))
      } else {
        setShowModal(!showModal)
      }
    },
    [showModal],
  )

  const openShareDialog = (url: string): void => {
    const left = window.screen.width / 2 - 600 / 2
    const top = window.screen.height / 2 - 600 / 2

    window.open(
      url,
      '',
      `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600,left=${left},top=${top}`,
    )
  }

  const copyUrl = (url: string): void => {
    navigator.clipboard.writeText(url).catch((error) => console.warn(error))
  }

  const onShareOptionClick = useCallback(
    (type: ShareOptionType): void => {
      setShowModal(false)

      const url = window.location.href
      const encodedUrl = encodeURIComponent(url)
      const encodedMedia = encodeURIComponent(media)
      const encodedTitle = encodeURIComponent(title)

      switch (type) {
        case ShareOptionType.FACEBOOK:
          openShareDialog(`https://www.facebook.com/sharer.php?u=${encodedUrl}`)
          break
        case ShareOptionType.LINKED_IN:
          openShareDialog(
            `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`,
          )
          break
        case ShareOptionType.PINTEREST:
          openShareDialog(
            `http://pinterest.com/pin/create/link/?url=${encodedUrl}&media=${encodedMedia}`,
          )
          break
        case ShareOptionType.WEIBO:
          openShareDialog(
            `http://service.weibo.com/share/share.php?url=${encodedUrl}&appkey=&title=${encodedTitle}&pic=&ralateUid=`,
          )
          break
        case ShareOptionType.COPY:
          copyUrl(url)
          break
      }
    },
    [media, title],
  )

  return (
    <StyledDictionaryWrapper>
      <StyledButton onClick={onButtonClick}>
        <StyledImage alt={dictionary.get('shareButtonText')} src={iconShare} />
        <StyledLabel>{dictionary.get('shareButtonText')}</StyledLabel>
      </StyledButton>
      {showModal && (
        <ShareModalWrapper ref={shareModalWrapperRef}>
          <ShareModal onClick={onShareOptionClick} options={shareOptions} />
        </ShareModalWrapper>
      )}
    </StyledDictionaryWrapper>
  )
}

const StyledImage = styled.img`
  height: ${rem(20)};
  margin-right: ${rem(16)};
  display: inline-block;
`

const StyledLabel = styled.span`
  line-height: ${rem(20)};
  white-space: nowrap;
`

const ShareModalWrapper = styled.div`
  position: absolute;
  bottom: 100%;
`

const StyledButton = styled.button`
  border: none;
  background: none;
  appearance: none;
  font-family: inherit;
  outline: none;
  color: ${Colors.OSLO_GREY};
  font-weight: 700;
  font-size: ${rem(15)};
  cursor: pointer;
  line-height: ${rem(20)};
  display: flex;
`

const StyledDictionaryWrapper = styled.div`
  margin: ${rem(288)} 0 ${rem(40)};
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default SocialShare
